<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="2" rounded="b" />
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";
import { mapState } from "vuex";

export default {
	name: "InicioContainer",
	components: { ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	computed: {
		...mapState(["usuario"]),
	},
	methods: {
		montarMenu() {
			let m = [];

			m.push({
				titulo: "Home",
				titulo_app: "Home",
				//icon: "mdi-bullseye-arrow",
				icon: "mdi-sale-outline",
				to: "/",
				tooltip: "Tela inicial do sistema Gestor",
			});

			if (this.usuario.idgrupo != 7) {
				m.push({
					titulo: "Vendas",
					titulo_app: "Vendas",
					//icon: "mdi-bullseye-arrow",
					icon: "mdi-sale-outline",
					to: "/dashboard/vendas",
					tooltip:
						"Dashboard de vendas antiga 150383 (em descontinuação)",
				});
			}

			if (this.permissao("dashboard_objetivos")) {
				m.push({
					titulo: "* Objetivos",
					titulo_app: "Objetivos",
					icon: "mdi-chart-bar-stacked",
					to: "/dashboard/objetivos",
					tooltip: "Dashboard de objetivos gerenciais",
				});
			}

			if (this.permissao("dashboard_bonificacao")) {
				m.push({
					titulo: "Bonificação",
					titulo_app: "Bonificação",
					icon: "mdi-chart-histogram",
					to: "/dashboard/bonificacao",
					tooltip: "Dashboard de bonificações gerenciais",
				});
			}

			if (this.permissao("dashboard_ranking_vendas")) {
				m.push({
					titulo: "Ranking vendas",
					titulo_app: "Ranking",
					icon: "mdi-finance",
					to: "/dashboard/ranking-vendas",
					tooltip: "Dashboard de ranking de vendas",
				});
			}

			if (this.permissao("dashboard_evolucao_vendas")) {
				m.push({
					titulo: "* Evolução vendas",
					titulo_app: "Evolução",
					icon: "mdi-chart-line",
					to: "/dashboard/evolucao",
					tooltip: "Dashboard de evolução de vendas",
				});
			}

			if (this.permissao("dashboard_aguardando")) {
				m.push({
					titulo: "Aguardando",
					titulo_app: "Aguardando",
					icon: "mdi-package-variant-closed",
					to: "/dashboard/aguardando",
					tooltip: "Dashboard de vendas em aguardando",
				});
			}

			if (this.permissao("dashboard_remessas")) {
				m.push({
					titulo: "Remessas",
					titulo_app: "Remessas",
					icon: "mdi-currency-usd",
					to: "/dashboard/remessas",
					tooltip:
						"Dashboard de acompanhamento de remessas de dinheiro e contas à pagar",
				});
			}

			if (this.permissao("dashboard_financeiro")) {
				m.push({
					titulo: "Financeiro",
					titulo_app: "Financeiro",
					icon: "mdi-chart-timeline-variant",
					to: "/dashboard/financeiro",
					tooltip: "Dashboard contas a receber",
				});
			}

			if (this.permissao("dashboard_compras")) {
				m.push({
					titulo: "* Compras",
					titulo_app: "Compras",
					icon: "mdi-chart-timeline-variant",
					to: "/dashboard/compras",
					tooltip: "Dashboard controle de pedidos de compras",
				});
			}

			if (this.permissao("dashboard_cd")) {
				m.push({
					titulo: "* CD",
					titulo_app: "CD",
					icon: "mdi-chart-timeline-variant",
					to: "/dashboard/cd",
					tooltip:
						"Dashboard controle de notas de fornecedor com frete",
				});
			}

      if (this.permissao("dashboard_categorias")) {
        m.push({
          titulo: "One Page - EvoVendas",
          titulo_app: "One Page - EvoVenda",
          icon: "mdi-chart-timeline-variant",
          to: "/dashboard/onepage",
          tooltip:
              "Dashboard One Page - Evolução de vendas",
        });
      }

      if (this.permissao("dashboard_tv")) {
        m.push({
          titulo: "Dashboard TV",
          titulo_app: "TV",
          icon: "mdi-finance",
          to: "/dashboard/tv",
          tooltip: "Dashboard de ranking de vendas",
        });
      }

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>